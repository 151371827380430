:root {
  --primary-color: #fff27a;
  --secondary-color: #999048;
  --focus-primary-color: #03bede;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.prompt-box {
  animation: 0.3s ease-out 0s 1 slideInFromBottom;
}

@keyframes zoomIn {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.zoom-out-anim {
  animation: 0.3s ease-out 0s 1 zoomIn;
}

@keyframes MoveUpDown {
  0%,
  100% {
    margin-top: 10px;
  }
  50% {
    margin-top: 0px;
  }
}

.move-up-down-inf {
  animation: MoveUpDown 1s ease-in-out infinite;
}
